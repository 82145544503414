<template>
  <div class="row gy-5 g-xl-8">
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NestedView",
});
</script>
